import(/* webpackChunkName: "ui-kit" */ './scripts/iframe-shell-resize-handler.js');

const chunksCallbacks = [];
window.addEventListener('DOMContentLoaded', () => {
    
  Promise.all([
    import(/* webpackChunkName: "ui-kit" */ 'prismjs'),
    import(/* webpackChunkName: "ui-kit" */ 'prismjs/plugins/line-numbers/prism-line-numbers.js')
  ]).then(([{default:Prism}]) => {
    Prism.highlightAll();
    chunksCallbacks.push(() => Prism.highlightAll())
  });

  // Search Bar
  const searchBar = document.querySelector('[widget="search-auto-complete-container"]');
  if (searchBar) {
    Promise.all([
      import(/* webpackChunkName: "ui-kit" */ './components/SearchAutoComplete.vue'),
      import(/* webpackChunkName: "ui-kit" */ 'vue')
    ])
    .then(([{default:Widget}, {createApp}]) => {
      const widget = createApp(Widget).mount(searchBar);
      widget.loadDataFrom('Sorry, this is still a work in progress :P')
    })
  }

  // Infinite Scroll
  const infiniteScrollerContainer = document.querySelector('[widget="infinite-scroller"]');
  if (infiniteScrollerContainer) {
    import(/* webpackChunkName: "ui-kit" */ './scripts/InfiniteScroller.js').then(({default:InfiniteScroller}) => {
      const { relatedArticles, slug } = infiniteScrollerContainer.dataset
      new InfiniteScroller(infiniteScrollerContainer, relatedArticles, slug, chunksCallbacks);
    })
  }

});

